<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        ref="carousel"
        class="over"
        v-model="show"
        width="500"
      >
        <v-carousel>
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            :src="item"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
          </v-carousel-item>
        </v-carousel>
      </v-dialog>
    </v-row>
    <Camera />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Camera from "./Camera";

export default {
  name: "Carousel",
  props: ["dialog", "items"],
  components: { Camera },
  data: () => ({
  }),
  methods: {
  },
  computed: {
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    },
    ...mapState(["wrkState"])
  }
};
</script>

<style scoped>
.over {
  z-index: 10000 !important;
}
</style>