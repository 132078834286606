export default{
      setConnectionStatus({ commit }, payload){
        commit('SETCONNECTIONSTATUS', payload);
      },
      resetState({ commit }, payload) {
        commit('RESETSTATE', payload);
      },
      loadDatosOperativos({ commit }, payload){
        commit('DATOSOPERATIVOS', payload);
      },
      addFraccion({ commit }, payload){
        commit('ADDFRACCION', payload);
      },  
      clearFracciones({ commit }) {
        commit('CLEARFRACCIONES');
      },
      clearFraccion({ commit }, payload) {
        commit('CLEARFRACCION', payload);
      },
      updateFraccion({ commit }) {
        commit('UPDATEFRACCION');
      },
      restoreFraccion({ commit }) {
        commit('RESTOREFRACCION');
      },
      setWrkFraccion({ commit }, payload){
        return new Promise((resolve, reject) => {
          try{
          commit('SETWRKFRACCION', payload);
          resolve();
        } catch(error){
          reject(new Error(error))
        }
      });
      },
      setWrkEdit({ commit }, payload){
        commit('SETWRKEDIT', payload);
      },
      setWrkRefresh({ commit }, payload){
        commit('SETWRKREFRESH', payload);
      },
      setWrkSelected({ commit }, payload){
        commit('SETWRKSELECT', payload);
      },
      setWrkStyleSize({ commit }, payload){
        commit('SETWRKSTYLESIZE', payload);
      },
      setExportedFraccion({ commit }, payload){
        return new Promise((resolve, reject) => {
          try{
            commit('SETEXPORTEDFRACCION', payload);
            resolve();
          } catch(error){
            reject(new Error(error))
          }
        });
      },
      setImage({ commit }, payload){
        return new Promise((resolve, reject) => {
          try{
            commit('SETIMAGE', payload);
            resolve();
          } catch(error){
            reject(new Error(error))
          }
        });
      },
      setImagePath({ commit }, payload){
        return new Promise((resolve, reject) => {
          try{
            commit('SETIMAGEPATH', payload);
            resolve();
          } catch(error){
            reject(new Error(error))
          }
        });
      },
      setMejoraDeleted({ commit }, payload){
        commit('SETMEJORADELETED', payload);
      },
      setPAF({ commit }, payload){
        commit('SETPAF', payload);
      },
      markMejora({ commit}, payload){
        commit('MARKMEJORA', payload);
      },
      moverMejora({ commit}, payload){
        return new Promise((resolve, reject) => {
          try{
          commit('MOVERMEJORA', payload);
          resolve();
        } catch(error){
          reject(new Error(error))
        }
      });
      },

      
      updateUsuario({ commit}, payload){
        return new Promise((resolve, reject) => {
          try{
          commit('UPDATEUSUARIO', payload);
          resolve();
        } catch(error){
          reject(new Error(error))
        }
      });
      }
};

