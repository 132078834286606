import { mapState } from 'vuex';
import * as L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
    props: ["map"],
    data() {
        return {
            layer: L.featureGroup(),
            wrkMap: new Map(),
            normalIcon: new L.Icon.Default(),
            selectedIcon: new L.Icon.Default()
        }
    },
    activated() {
//        console.log("Fraccion " + this.wrkFraccion.fraccionesWrapper.id + ", " + this.colName + " activados...");
        this.init();
        this.updateGui();
    },
    mounted() {
//        this.init();
    },
    methods: {
        init: function () {
//            console.debug('iniciando mapa ' + this.colName);
            if (this.map.hasLayer(this.layer)) {
//                console.debug('removiendo capa del mapa...');
                this.map.removeLayer(this.layer);
            }

            this.layer.clearLayers();
            this.wrkMap.clear();

            if (this.wrkFraccion[this.colName]) {
//                console.debug('cargando ' + this.wrkFraccion[this.colName].length + ' mejoras...');
                this.wrkFraccion[this.colName].forEach(alam => {
                    if (alam.deleted === null || !alam.deleted) {
                        this.addAlam(alam, this.typeName === this.ModelTypes.Alambrados.name ? this.normalStyle : this.normalIcon);
                    } else {
                        console.debug(alam.id + ' eliminada, no se carga...');
                    }
                });


                this.map.addLayer(this.layer);
            }
            this.map.invalidateSize();
        },

        updateGui: function () {
            if (this.wrkState.selected && this.wrkState.selected.type === this.typeName &&
                this.wrkMap.has(this.wrkState.selected.id)) {
                if (this.selectedStyle) {
                    this.wrkMap[this.wrkState.selected.id].setStyle(this.selectedStyle);
                } else {
                    this.wrkMap[this.wrkState.selected.id].setIcon(this.selectedIcon);
                }
            }
        },


        addAlam: function (alam, style) {
            let tmp;

            if (this.typeName === this.ModelTypes.Alambrados.name) {
                tmp = L.polyline(alam.geoInfo, { 
                    lineCap: 'square',
                    showMeasurements: true, 
                    measurementOptions: { 
                        formatDistance: this.distanceInMeters
                    } 
                });
                tmp.setStyle(style);
            } else {
                tmp = L.marker(alam.geoInfo);
                tmp.setIcon(style);
            }

            tmp.id = alam.id;
            tmp.on("click", this.click);
            tmp.on("pm:edit", this.update);
            this.wrkMap[alam.id] = tmp;
            this.layer.addLayer(tmp);
        },
        click: function (e) {
            L.DomEvent.stopPropagation(e);

            if (this.isInOtherEdition) {
                this.$toasted.info("No es posible ... edicion en curso de " + this.typeName, {
                    duration: 1500
                });
            } else {
                this.$store.dispatch('setWrkSelected', {
                    id: e.target.id,
                    type: this.typeName,
                    geo: true
                });
            }
        },
        update: function (e) {
            let line;
            let style;
            if (this.typeName === this.ModelTypes.Alambrados.name) {
                line = this.getLineVertexs(e.target);
                style = this.selectedStyle;
            } else {
                line = this.getPointVertexs(e.target);
                style = this.selectedIcon;
            }

            let id = e.target.id;
            let index = this.wrkFraccion[this.colName].findIndex(elem => elem.id === id);

            if (index >= 0) {
                let element = this.wrkFraccion[this.colName][index];
                element.geoInfo = line;

                this.map.removeLayer(this.wrkMap[id]);
                this.map.removeLayer(e.target);

                this.addAlam(element, style);
                if (this.typeName !== this.ModelTypes.Alambrados.name) {
                    this.wrkMap[id].dragging.enable();
                    this.wrkMap[id].pm.enable({preventMarkerRemoval: true});
                } else {
                    this.wrkMap[id].pm.enable({removeLayerBelowMinVertexCount: false});
                }
            }
        },
        getLineVertexs: function (geom) {
            let res = [];

            geom._latlngs.forEach(element => {
                res.push([element.lat, element.lng]);
            });

            return res;
        },
        getPointVertexs: function (geom) {
            return [geom._latlng.lat, geom._latlng.lng];
        },
        distanceInMeters: function (d) {
            var unit = 'm';

            if (d < 100) {
                return d.toFixed(1) + ' ' + unit;
            } else {
                return Math.round(d) + ' ' + unit;
            }
        }
    },
    computed: mapState({
        wrkFraccion: "wrkFraccion",
        wrkState: "wrkState",
        wrkStyleSize: "wrkStyleSize",
        edit(state) {
            return state.wrkState.edit && state.wrkState.edit.type.indexOf(this.typeName) > -1;
        },
        isInOtherEdition(state) {
            return state.wrkState.edit && state.wrkState.edit.type.indexOf(this.typeName) < 0;
        }
    }),
    watch: {
        wrkStyleSize(newSel) {
            let size = this.SyleSizes.normal;

            switch (newSel){
                case 'normal':
                    size = this.SyleSizes.normal;
                    break;
                case 'big':
                    size = this.SyleSizes.big;
                    break;
                case 'small':
                    size = this.SyleSizes.small;
                    break;    
            }

            if (this.normalStyle) {
                this.normalStyle.weight = size;
                this.layer.setStyle({ weight: size });
            }
            if (this.selectedStyle) {
                this.selectedStyle.weight = size;
            }
        },
        wrkState(newSel, oldSel) {
            if (oldSel.selected && oldSel.selected.type === this.typeName &&
                this.wrkMap[oldSel.selected.id]) {
                if (this.normalStyle) {
                    this.wrkMap[oldSel.selected.id].setStyle(this.normalStyle);
                } else {
                    this.wrkMap[oldSel.selected.id].setIcon(this.normalIcon);
                }
                this.wrkMap[oldSel.selected.id].pm.disable();
            }
            if (newSel.selected && newSel.selected.type === this.typeName && this.wrkMap[newSel.selected.id]) {
                let layer = this.wrkMap[newSel.selected.id];
                if (this.selectedStyle) {
                    layer.setStyle(this.selectedStyle);
                } else {
                    layer.setIcon(this.selectedIcon);
                }
//                if (!newSel.selected.geo) {
                    if (this.typeName === this.ModelTypes.Alambrados.name) {
                        this.map.fitBounds(layer.getBounds(), {
                            paddingTopLeft: [200, 0]
                        });
                    } else {
                        this.map.fitBounds(layer.getLatLng().toBounds(50), {
                            paddingTopLeft: [150, 0]
                        });
                    }
//                }
                if (this.edit) {
                    if (this.typeName !== this.ModelTypes.Alambrados.name) {
                        this.wrkMap[newSel.selected.id].pm.enable({ editable: false, draggable: true,
                            preventMarkerRemoval: true });
                    } else {
                        this.wrkMap[newSel.selected.id].pm.enable({ editable: false, draggable: false,
                            removeLayerBelowMinVertexCount: false});    
                    }
                }
            }
            if (newSel.refresh && newSel.refresh.type.indexOf(this.typeName) > -1) {
                this.init();
                this.$store.dispatch('setWrkRefresh', null);
            }
        },
        edit() {
            if (this.wrkState.selected && this.wrkState.selected.type === this.typeName &&
                this.wrkMap[this.wrkState.selected.id]) {
                if (this.edit) {
                    if (this.typeName !== this.ModelTypes.Alambrados.name) {
                        this.wrkMap[this.wrkState.selected.id].dragging.enable();
                        this.wrkMap[this.wrkState.selected.id].pm.enable({
                            preventMarkerRemoval: true
                          });
                    } else {
                        this.wrkMap[this.wrkState.selected.id].pm.enable({
                        removeLayerBelowMinVertexCount: false
                          });        
                    }
                } else {
                    if (this.typeName !== this.ModelTypes.Alambrados.name) {
                        this.wrkMap[this.wrkState.selected.id].dragging.disable();
                    }
                    this.wrkMap[this.wrkState.selected.id].pm.disable();
                }
            }
        }
    }
}