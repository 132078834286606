<template>
  <v-row>
    <v-card flat>
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Fracciones</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="showSearchDialog = true" icon>
            <v-icon>mdi-cloud-search-outline</v-icon>
          </v-btn>
          <v-btn :loading="uploading" :disabled="selected.length === 0" @click="askToUpload(selected[0])" icon>
            <v-icon>mdi-cloud-upload-outline</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
          <v-container>
          <v-row>
            
          </v-row>  
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="fracciones"
                v-model="selected"
                :single-select="true"
                show-select
                locale="es"
                class="elevation-1"
                ref="table"
              >
                <template v-slot:[`item.modified`]="{ item }">
                  <span>{{ getUltActFraccion(item.id) }}</span>
                </template>
                <template v-slot:[`item.exported`]="{ item }">
                  <span>{{ item.exported ? new Date(item.exported).toLocaleString() : null }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="!selected || selected.length === 0" @click="edit"
          >Editar fraccion</v-btn
        >
        <v-btn
          :disabled="!selected || selected.length === 0"
          @click="clear"
          color="error"
          >Eliminar fraccion</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-row justify="center">
      <v-dialog ref="dialog" class="over" v-model="showSearchDialog" width="500">
        <v-card>
          <v-card-title>Buscar / Descargar desde servidor INC</v-card-title>
          <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="opts.regional"
                label="Regional"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="opts.colonia"
                label="Colonia"
              ></v-text-field>
            </v-col>
            </v-row>
            <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="opts.inmueble"
                label="Inmueble"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="opts.fraccion"
                label="Fraccion"
              ></v-text-field>
            </v-col>
            </v-row>
            <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="opts.nroColono"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                label="Nro Colono"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="opts.nombreColono"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                label="Colono"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn
              :loading="searching"
              :disabled="
              (!opts.regional &&
              !opts.colonia &&
              !opts.inmueble &&
              !opts.fraccion &&
              !opts.nroColono &&
              !opts.nombreColono) ||
              loading
              "
              small
              @click.stop="search"
            >Buscar en servidor
            <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-row>
        </v-container>
          <v-container>
            <v-row justify="center">
              <v-data-table
                :headers="[
                  { text: 'Fraccion', value: 'numeroFraccion', align: 'end' },
                  {
                    text: 'Colonia',
                    value: 'nombreColonia',
                    align: 'start',
                    sortable: true,
                  },
                  {
                    text: 'Inmueble',
                    value: 'numeroInmueble',
                    align: 'start',
                    sortable: true,
                  },
                ]"
                v-model="searchSelected"
                :items="searchFracciones"
                item-key="id"
                :single-select="false"
                show-select
                locale="es"
                class="elevation-1"
              >
                <template v-slot:item="{ item, isSelected, select }">
                  <tr>
                    <td>
                      <v-checkbox
                        v-show="!item.exists"
                        :value="isSelected"
                        @change="select($event)"
                      ></v-checkbox>
                      <v-icon v-show="item.exists">mdi-check</v-icon>
                    </td>
                    <td>{{ item.numeroFraccion }}</td>
                    <td>{{ item.nombreColonia }}</td>
                    <td>{{ item.numeroInmueble }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-row>
          </v-container>
          </v-card-text>
          <v-card-actions center>
            <v-spacer></v-spacer>
            <v-btn @click="showSearchDialog = false" :disabled="loading" >Cerrar</v-btn>
            <v-btn :loading="loading" :disabled="searchSelected.length === 0" @click="download"
              >Descargar fraccion</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <ValidationUI :errors="validationErrors" :dialog="validationDialog" 
          @closeValidation="validationDialog = false">
    </ValidationUI>
    <p></p>
      <div id="map" ref="map"></div>
  </v-row>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import controls from "../mixins/controls.js";
import { mapState } from "vuex";
import ValidationUI from "./ValidationUI";
import batch from "../mixins/batch.js";
import * as L from "leaflet";
import "leaflet.offline";

export default {
  name: "Home",
  mixins: [controls, batch],
  components: { ValidationUI },
  mounted() {
    this.map = L.map(this.$refs.map), {
      dragging: L.Browser.mobile,
      touchZoom: true,
      maxZoom: 18,
      minZoom: 7,
      wheelPxPerZoomLevel: 600
    }; // this.$refs.map
    this.map.setView([-33.183, -55.909], 9);
    console.log(this.map.getSize());

//    this.osmLayer = L.tileLayer.offline("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {subdomains: 'abc'});
    this.osmLayer = L.tileLayer.offline("https://mt.google.com/vt/lyrs=s&x={x}&y={y}&z={z}");
    this.osmLayer.addTo(this.map);
    this.osmLayer.on("savestart", this.savingTiles);
    this.osmLayer.on("saveend", this.tilesSaved);

    this.osmControl = L.control.savetiles(this.osmLayer, {confirm: this.saveTiles, zoomlevels: [9,10,11,12,13,14,15,16,17,18]});
    this.osmControl.addTo(this.map);
    document.querySelector(".savetiles.leaflet-bar").style.visibility = "hidden";
    console.log(this.map.getBounds());

//    this.osmControl._rmTiles();
  },
  data() {
    return {
/*
      proj: proj4(
        "+proj=utm +zone=21 +south +datum=WGS84 +units=m +no_defs",
        "EPSG:4326"
      ),
*/
      validationDialog: false,
      validationErrors: [],
      showSearchDialog: false,
      searching: false,
      loading: false,
      uploading: false,
      opts: {
        regional: "",
        colonia: "",
        inmueble: "",
        fraccion: "",
        nroColono: "",
        nombreColono: ""
      },
      headers: [
        {
          text: "Fraccion",
          value: "fraccionesWrapper.numeroFraccion",
          align: "end",
        },
        {
          text: "Colonia",
          value: "fraccionesWrapper.nombreColonia",
          align: "start",
          sortable: true,
        },
        {
          text: "Inmueble",
          value: "fraccionesWrapper.numeroInmueble",
          align: "start",
          sortable: true,
        },
        {
          text: "Fecha ultima modificacion",
          value: "modified",
          align: "start",
        },
        {
          text: "Fecha ultima exportacion",
          value: "exported",
          align: "start",
        }
      ],
      selected: [],
      searchFracciones: [],
      searchSelected: [],
      cloudFilters: [],
      tableFilters: [],
      map: null,
	    osmLayer: null,
    	osmControl: null
    };
  },
  methods: {
    clearState: function(){
      this.$store.dispatch("resetState")
      .then(() => {
        return this.$toasted.show("Datos reseteados", {
          type: "success",
          duration: 1500,
        });
      })
      .catch(() => {
        return this.$toasted.show("Error al resetear datos", {
          type: "error",
          duration: 3000,
        });
      });
    },
    edit: function () {
      this.$store
        .dispatch("setWrkFraccion", this.selected[0])
        .then(() => {
          this.$router.push("map");
        })
        .catch(() => {
          this.$toasted.show("No se pudo cargar fraccion de trabajo...", {
            type: "error",
            autoHideDelay: 3000,
          });
        });
    },
    download: function () {
      let promises = [];

      for (let selected of this.searchSelected){
        let id = selected.id;
        let geom;

        if (!this.fracciones.find((el) => el.id === id)) { // no está descargada
          promises.push(
            this.$getInventario(id).then((res) => {
//              console.log(res);
              let tmp = this.transformFraccionFromWS(res);
//              console.log(tmp);
              geom = tmp.geoInfo;

              return this.$store.dispatch("addFraccion", tmp);
            })
            .then(() => {
              selected.exists = true;
              console.log("Fraccion descargada...");
              return this.$toasted.show("Fraccion " + id + " descargada", {
                type: "success",
                duration: 1500,
              });
            })
            .then(() => {
              console.log("Bajando fotos...");
              this.downloadFotosFraccion(this.fracciones.find((el) => el.id === id));
            })
            .then(() => {
              return new Promise((resolve) => {
                let layer = L.polygon(geom);
                layer.addTo(this.map);
                let bounds = layer.getBounds();
                this.map.fitBounds(bounds);
                console.log(this.map.getBounds());
                this.osmControl._saveTiles();

                resolve();
              });
            })
          );
        } 
      }

      this.loading = true;
      Promise.all(promises).then(() => {        
        return this.$toasted.show("Fraccion(es) descargada(s)", {
          type: "success",
          duration: 1500,
        });
      })
      .catch((e) => {
        console.log(e);
        return this.$toasted.show("Error al descargar fracciones (" + e.obj + ")", {
          type: "error",
          duration: 4000,
        });
      })
      .finally(() => {
        this.loading = false;
        this.selected = [];
      });
    },
    savingTiles: function(status){
      console.log("Descargando imagenes...");
      this.$toasted.show("Descargando " + status.lengthToBeSaved + " imagenes...", {type: "info", duration: 10000});
    },
    tilesSaved: async function(status){
      console.log("Se descargaron " + status.lengthSaved + " imagenes...");
      this.$toasted.show("Se descargaron " + status.lengthSaved + " imagenes...", {type: "info", duration: 15000});
    },
    askToUpload: function(fraccion){
      let checkError = this.checkMejorasFraccion(fraccion);
      if (checkError && checkError.length > 0){
        this.validationErrors = checkError;
        this.validationDialog = true;
      } else {
        if (!fraccion.exported){
          this.upload(fraccion);
          return;
        }
/*
        this.$toasted.show("Esta fraccion ya fue exportada ... ¿Desea exportarla nuevamente?", {
          Icon: 'mdi-cloud-upload-outline',
          iconPack: 'mdi',
          action: {
            text: 'Aceptar',
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
              this.upload(frac);
            }
          },
          duration: 3500
        });
*/        
        this.$toasted.show("Esta fraccion ya fue exportada ...", {
          Icon: 'mdi-cloud-upload-outline',
          iconPack: 'mdi',
          duration: 3500
        });
      }
    },
    checkChanges: function(frac){
      let tmp = this.transformFraccionToWS(frac);
      console.log(tmp);

      this.$modifiedInventario(tmp).then((res) => {
        if (res.length === 0){
          this.upload(frac);
        } else {
          this.$toasted.show("La fraccion tiene cambios en el servidor ... ¿Desea continuar?", {
            Icon: 'mdi-cloud-upload-outline',
            iconPack: 'mdi',
            action: {
              text: 'Aceptar',
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
                this.upload(frac);
              }
            },
            duration: 3500
          });
        }       
      })
        .catch((error) => {
          console.log(error.message);
          return this.$toasted.show("Error al chequear cambios de la fraccion en el servidor (" + error.message + ')', {
            type: "error",
            duration: 6000,
          });
        });
    },
    upload: function (frac) {
      this.uploading = true;

      console.log("idInventario: " + frac.idInventario);

      if (frac.idInventario){
        this.uploadFotosFraccion(frac).then(() => {
          console.log("modificando fraccion en el inventario...");
          let tmp = this.transformFraccionToWS(frac);
          return this.$modifyInventario(tmp);
        }).then(() => {
          console.log("marcando como exportada...");
          return this.$store.dispatch("setExportedFraccion", {id: frac.id, idInventario: null});
        }).then(() => {
          console.log("fraccion exportada exitosamente...");
          this.$toasted.show("Fraccion subida exitosamente", {
            type: "success",
            duration: 3500,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.show("Error al subir fraccion (" + error.message ? error.message : error + ')', {
            type: "error",
            duration: 6000,
          });
        })
        .finally(() => {
          this.uploading = false;
        });
      } else {
        this.uploadFotosFraccion(frac).then(() => {
          console.log("creando fraccion en el inventario...");
          let tmp = this.transformFraccionToWS(frac);
          return this.$setInventario(tmp);
        }).then((id) => {
          console.log("marcando como exportada y seteando Id...");
          return this.$store.dispatch("setExportedFraccion", {id: frac.id, idInventario: id});
        }).then(() => {
          console.log("fraccion exportada exitosamente...");
          this.$toasted.show("Fraccion subida exitosamente", {
            type: "success",
            duration: 3500,
          });
        }).catch((error) => {
          console.log(error);
          this.$toasted.show("Error al subir fraccion (" + error.message ? error.message : error + ')', {
            type: "error",
            duration: 6000,
          });
        })
        .finally(() => {
          this.uploading = false;
        });
      }
    },
    checkFraccion: function(fraccion){
      let res = this.checkMejorasFraccion(fraccion);
      if (res && res.length > 0){
        this.validationErrors = res;
        this.validationDialog = true;
      } else {
          this.$toasted.show("Fraccion correcta", {
            type: "success",
            duration: 4000,
          });
      }
    },
    clear: function () {
      this.$toasted.show("¿Confirma eliminar esta fraccion?", {
        Icon: 'mdi-minus',
        iconPack: 'mdi',
        action: {
          text: 'Aceptar',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
            this.$store
              .dispatch("clearFraccion", this.selected[0].id)
              .then(() => {
                this.$toasted.show("Fraccion eliminada", {
                  type: "success",
                  duration: 1500,
                });
              })
              .catch(() => {
                this.$toasted.show("Error al eliminar la fraccion", {
                  type: "error",
                  autoHideDelay: 3000,
                });
              });
          }
        },
        duration: 3500
      });
    },
    clearAll: function () {
      this.$store
        .dispatch("clearFracciones")
        .then(() => {
          this.$toasted.show("Fracciones eliminadas", {
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.$toasted.show("Error al eliminar las fracciones", {
            type: "error",
            autoHideDelay: 3000,
          });
        });
    },
      getUltActFraccion: function(id) {
        let res = this.$store.getters.getUltActViviendas(id);
        let tmp = 0;

        tmp = this.$store.getters.getUltActAlambrados(id);
        if (tmp > res){
          res = tmp;
        }
        tmp = this.$store.getters.getUltActOtrosElem(id);
        if (tmp > res){
          res = tmp;
        }
        tmp = this.$store.getters.getUltActInstGanado(id);
        if (tmp > res){
          res = tmp;
        }
        tmp = this.$store.getters.getUltActMontes(id);
        if (tmp > res){
          res = tmp;
        }
        tmp = this.$store.getters.getUltActGalpones(id);
        if (tmp > res){
          res = tmp;
        }
        tmp = this.$store.getters.getUltActSalas(id);
        if (tmp > res){
          res = tmp;
        }
        tmp = this.$store.getters.getUltActPozos(id);
        if (tmp > res){
          res = tmp;
        }
        tmp = this.$store.getters.getUltActGallineros(id);
        if (tmp > res){
          res = tmp;
        }
        tmp = this.$store.getters.getUltActOtrasMejoras(id);
        if (tmp > res){
          res = tmp;
        }
        tmp = this.$store.getters.getUltActAgua(id);
        if (tmp > res){
          res = tmp;
        }

        return (res > 0) ? new Date(res).toLocaleString() : null;
      },


  search() {
      this.searching = true;
      this.searchFracciones = [];

      //      console.log('Constantes: ' + this.ModelTypes);
      this.$searchFracciones(this.opts)
        .then((res) => {
          res.forEach((element) => {
            element.exists = this.fracciones && this.fracciones.find((frac) => frac.id === element.id) ? true : false;
          });

          this.searchFracciones = res;
          this.showSearchDialog = true;
        })
        .catch((e) => {
          console.log(e);
          return this.$toasted.show("Error al buscar fraccion (" + e.obj + ")", {
            type: "error",
            duration: 5000,
          });
        })
        .finally(() => {
          this.searching = false;
        });
    },
    searchTypeFromWS(wsElement) {
      let res = undefined;

      for (const key of Object.keys(this.ModelTypes)) {
        if (this.ModelTypes[key].name === wsElement.type) {
          res = key;
          break;
        }
      }

      if (!res) {
        // no encontre el tipo en los que se traducen directamente
        console.log("no encontre el tipo en los que se traducen directamente");
        let wsType = this.TypesFromWS.find((el) => el.name === wsElement.type);
        if (wsType) {
          if (!wsElement[wsType.field]){
            throw wsElement.type + ", id " + wsElement.id + ": no tiene " + wsType.field;
          }
          let subType = wsType.subTypes.find(
            (el) => wsElement[wsType.field].id === el.id
          );
          if (subType) {
            console.log('encontre subtipo ' + JSON.stringify(subType));
            res = subType.type;
          }
        }
      }

      return res;
    },
    searchTypeToWS(element) {
      let res = undefined;

      let subType = null;
      for (const wsType of this.TypesFromWS) {
        subType = wsType.subTypes.find((el) => el.type === element.type);
        if (subType) {
          console.log('encontre subtipo ' + JSON.stringify(subType));
          res = wsType.name;
          break;
        }
      }

      if (!res) {
        for (const key of Object.keys(this.ModelTypes)) {
          if (this.ModelTypes[key].name === element.type) {
            res = element.type;
            break;
          }
        }
      }

      return res;
    },
    transformFraccionFromWS(frac) {
      let fraccion = JSON.parse(JSON.stringify(frac));

      fraccion.idInventario = fraccion.id;
      fraccion.id = fraccion.fraccionesWrapper.id;
      fraccion.exported = null;
      fraccion.geoInfo = this.transformGeomFromWS1(fraccion.fraccionesWrapper.geoInfo, "MultiPolygon");
      fraccion.fraccionesWrapper.puntoAccesoGeoInfo = this.transformGeomFromWS1(fraccion.fraccionesWrapper.puntoAccesoGeoInfo, 'Point');

      let tmp = null;
      fraccion.mejoras.forEach((element) => {
        let typeName = this.searchTypeFromWS(element);

        if (typeName) {
          if (fraccion[this.ModelTypes[typeName].field] == undefined) {
            fraccion[this.ModelTypes[typeName].field] = [];
          }

          tmp = JSON.parse(JSON.stringify(element));
//          console.log(tmp.type + " => " + this.ModelTypes[typeName].name);
          tmp.type = this.ModelTypes[typeName].name;

          if (tmp.geoInfo) {
            tmp.geoInfo = this.transformGeomFromWS1(tmp.geoInfo, tmp.type);
          }

          tmp.modified = null;
          fraccion[this.ModelTypes[typeName].field].push(tmp);
        } else {
          console.log("No pude encontrar tipo: " + element.type);
        }
      });

      delete fraccion.mejoras;

      return fraccion;
    },
    transformFraccionToWS(fraccion) {
      let res = JSON.parse(JSON.stringify(fraccion));

      res.id = res.idInventario;
      
      delete res.idInventario;
      delete res.geoInfo;
      delete res.exported;

      let mejoras = [];

      res.fraccionesWrapper.puntoAccesoGeoInfo = this.transformGeomToWS1(res.fraccionesWrapper.puntoAccesoGeoInfo);

      for (const key of Object.keys(res)) {
        if (Array.isArray(res[key])) {
          for (let element of res[key]){
            let type = this.searchTypeToWS(element);
            if (!type){
              console.log("No pude encontrar el tipo para " + element.type);
              continue;
            }

            if (element.deleted && element.id.toString().startsWith(this.General.tmpId)){
              console.log("---------> Elemento creado y eliminado (se ignora)");
              console.log(element);
              continue;
            }


            element.type = type;
            element.geoInfo = this.transformGeomToWS1(element.geoInfo);

            delete element.modified;
            delete element.toMove;
            
            if (element.id.toString().startsWith(this.General.tmpId)) {
              element.id = null;
              element.editable = false;
              element.tiposOtrosElementosId = null;
              element.idMejoraOriginal = null;
            }

            // Borro ids temporales de propiedades que son arrays
            for (const prop of Object.keys(element)) {
              if (Array.isArray(element[prop])) {
                for (let arrayElement of element[prop]){
                  if (arrayElement.id.toString().startsWith(this.General.tmpId)) {
                    arrayElement.id = null;
                  }
                }
              }
            }

            if (element["fotosSIMCollection"]){
              for (let foto of element["fotosSIMCollection"]){
                foto.fotoFisica = null;
              }
            }

            element.activo = !element.deleted;
            delete element.deleted;

            mejoras.push(JSON.parse(JSON.stringify(element)));
          }

          delete res[key];
        }
      }
      res.mejoras = mejoras;

      return res;
    },
    transformGeomFromWS1(geoInfo, type) {
      if (geoInfo === null) return null;

      let tmp = geoInfo.split(" ");
      let res = null;

      if (tmp.length === 1){
        let yx = tmp[0].split(",");
        let x = parseFloat(yx[1]);
        let y = parseFloat(yx[0]);

        if (type === this.ModelTypes.Alambrados.name){
          res = [[x, y] , [x + 0.001, y + 0.001]];
        } else {
          res = [x, y];
        }
      } else {
      res = [];

      tmp.forEach(coords => {
        let yx = coords.split(",");
        res.push([parseFloat(yx[1]), parseFloat(yx[0])]);
      });
      }
//            console.log(geoInfo);
//            console.log(res);
      return res;
    },
    transformGeomToWS1(geoInfo) {
      if (geoInfo === null) return null;

      let res;
      if (Array.isArray(geoInfo[0])){ // no es un punto
        res = "";

        geoInfo.forEach(coords => {
          res = res.concat(coords[1] + "," + coords[0] + " ");
        });

      } else { // es un punto
        res = geoInfo[1] + "," + geoInfo[0];
      }

      return res;
    },
    createFilterFunction(filters){
      let funStr = "";
      let i = 0;

      do {
        funStr = funStr + filters[i].name + " === " + filters[i].value
        i = i + 1;

        if (i != (filters.length - 1)){
          funStr + " and ";
        }
      } while (i < filters.length);

      return new Function(funStr);
    }
  },
  computed: {
    tableFilteredFracciones(){
      if (this.tableFilters.length === 0){
        return this.fracciones;
      } else {
        return this.cloudFilters.forEach(this.createFilterFunction(this.tableFilters));
      }
    },
    ...mapState(["fracciones"])
  }
};
</script>

<style scoped>
#map {
  height: 600px;
  width: 100%;
  z-index: 0;
}

.hideButtons input[type='number'] {
    -moz-appearance:textfield;
}
.hideButtons input::-webkit-outer-spin-button,
.hideButtons input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>