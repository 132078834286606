<template>
  <v-row justify="center">
    <v-dialog
      ref="salaDialog"
      v-if="data"
      class="over"
      :fullscreen="true"
      persistent
      v-model="show"
      width="500"
    >
      <!-- v-if="data" -->
      <v-card>
        <v-card-title>
          <span class="headline">Conexion UTE</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-container>
              <v-row>
                <v-textarea
                  :disabled="!edit"
                  v-model="data.descripcion"
                  label="Descripcion"
                ></v-textarea>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-select 
                    :disabled="!edit"
                    label="*En construccion?"
                    v-model="data.enConstruccion"
                    :items="boolItems" 
                    :menu-props="{closeOnContentClick: true}"
                    :rules="[requiredBool('en construccion')]"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.enConstruccion = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-select 
                    :disabled="!edit"
                    label="Propiedad INC?"
                    v-model="data.propiedadINC"
                    :menu-props="{closeOnContentClick: true}"
                    :items="boolItems" 
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.propiedadINC = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :disabled="!edit"
                    v-model="data.porcentajePropiedadDelColono"
                    label="Derecho indemnizatorio(%)"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                    :rules="[num_range('Derecho indem(%)', 0, 100)]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                <v-select return-object
                  :disabled="!edit"
                  label="*Estado conservacion"
                  item-text="nombre"
                  v-model="data.tiposDeEstadosConservacionMejorasId"
                  :items="$store.state.tiposDeEstadosConservacionMejoras"
                  :menu-props="{closeOnContentClick: true}"
                  :rules="[required('estado de conservacion')]"
                >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeEstadosConservacionMejorasId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
                </v-col>
                <v-col cols="3">
                <v-select
                  :disabled="!edit"
                  label="*Tipo Conexion"
                  v-model="data.tiposDeConexionUTEId"
                  item-text="nombre"
                  return-object
                  :items="$store.state.tiposDeConexionUTE"
                  :menu-props="{closeOnContentClick: true}"
                  :rules="[required('tipo conexion')]"
                >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeConexionUTEId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :disabled="!edit"
                    v-model="data.distanciaAlContador"
                    :hint="distanciaHint"
                    label="*Distancia contador"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                    :rules="[required('distancia al contador'), positive('distancia al contador')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :disabled="!edit"
                    v-model="data.carga"
                    label="Carga"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                    :rules="[positive('carga')]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="edit" color="primary" :disabled="!valid" @click="commit">Guardar</v-btn>
          <v-btn v-if="edit" @click="cancel">Cancelar</v-btn>
          <v-btn v-if="!edit" @click="close">Cerrar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import validations from "../mixins/validations.js";

export default {
  name: "ConexionUTE",
  props: ["data", "dialog", "edit"],
  mixins: [validations],
  mounted() {},
  data() {
    return {
      valid: false,
      distanciaHint: '',
      boolItems: [{text: 'Si', value: true}, {text: 'No', value: false}]
    };
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    commit: function () {
      this.$emit("commit");
    },
    cancel: function () {
      this.$emit("cancel");
    },
  },
  computed: {
    distancia() {
      return (this.data) ? this.data.distanciaAlContador : null;
    },
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    distancia(newSel){
      if (newSel > 999){
        this.distanciaHint = "atencion: distancia mayor a 999...";
      } else {
        this.distanciaHint = "";
      }
    },
    show(newSel) {
      if (newSel) {
        this.$nextTick(function () {
          this.$refs.salaDialog.stackMinZIndex = 10000;
          this.$refs.form.validate();
        });
      }
    },
  },
};
</script>

<style scoped>
.over {
  z-index: 10000 !important;
}
</style>
