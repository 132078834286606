<template>
  <v-row justify="center">
    <v-dialog
      ref="salaDialog"
      v-if="data"
      class="over"
      :fullscreen="true"
      persistent
      v-model="show"
      width="500"
    >
      <!-- v-if="data" -->
      <v-card>
        <v-card-title>
          <span class="headline">Monte Forestal</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-container>
              <v-row>
                <v-textarea
                  :disabled="!edit"
                  v-model="data.descripcion"
                  label="Descripcion"
                ></v-textarea>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-select 
                    :disabled="!edit"
                    label="*En construccion?"
                    v-model="data.enConstruccion"
                    :items="boolItems" 
                    :menu-props="{closeOnContentClick: true}"
                    :rules="[requiredBool('en construccion')]"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.enConstruccion = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-select 
                    :disabled="!edit"
                    label="Propiedad INC?"
                    v-model="data.propiedadINC"
                    :menu-props="{closeOnContentClick: true}"
                    :items="boolItems" 
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.propiedadINC = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :disabled="!edit"
                    v-model="data.porcentajePropiedadDelColono"
                    label="Derecho indemnizatorio(%)"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                    :rules="[num_range('Derecho indem(%)', 0, 100)]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3">
                <v-select return-object
                  :disabled="!edit"
                  label="*Estado conservacion"
                  required
                  v-model="data.tiposDeEstadosConservacionMejorasId"
                  :items="$store.state.tiposDeEstadosConservacionMejoras"
                  item-text="nombre"
                  :menu-props="{closeOnContentClick: true}"
                  :rules="[required('estado de conservacion')]"
                >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeEstadosConservacionMejorasId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
                </v-col>
                <v-col cols="3">
                <v-select return-object
                  :disabled="!edit"
                  label="*Tipo"
                  required
                  v-model="data.tiposDeMontesId"
                  :items="tiposDeMontesForestales"
                  :menu-props="{closeOnContentClick: true}"
                  item-text="nombre"
                  :rules="[required('tipo monte')]"
                >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeMontesId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                <v-text-field
                  :disabled="!edit"
                  label="*Edad"
                  :hint="edadHint"
                  v-model="data.edad"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                  :rules="[required('edad'), positiveAndInteger('edad')]"
                ></v-text-field>
                </v-col>
                <v-col cols="3">
                <v-select return-object
                  :disabled="!edit"
                  label="*Estado sanitario"
                  required
                  v-model="data.tiposDeEstadoSanitarioId"
                  :items="$store.state.tiposDeEstadoSanitario"
                  item-text="nombre"
                  :menu-props="{closeOnContentClick: true}"
                  :rules="[required('estado sanitario')]"
                >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeEstadoSanitarioId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
                </v-col>
                <v-col cols="2">
                <v-text-field
                  :disabled="!edit"
                  label="*Superficie"
                  v-model="data.superficie"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                  :rules="[required('superficie'), positive('superficie')]"
                ></v-text-field>
                </v-col>
                <v-col cols="2">
                <v-text-field
                  :disabled="!edit"
                  label="*Cantidad de arboles"
                  v-model="data.cantidadDeArboles"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                    type="number"
                  :rules="[required('cantidad de arboles'), positiveAndInteger('cantidad de arboles')]"
                ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                <v-text-field
                  :disabled="!edit"
                  label="*DAP"
                  v-model="data.dap"
                  :hint="dapHint"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                  :rules="[required('dap'), positive('dap')]"
                ></v-text-field>
                </v-col>
                <v-col cols="3">
                <v-select 
                  :disabled="!edit"
                  label="*Rebrote"
                  v-model="data.tieneRebrote"
                  :menu-props="{closeOnContentClick: true}"
                    :items="boolItems"
                  :rules="[requiredBool('rebrote')]"
                >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tieneRebrote = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
                </v-col>
                <v-col cols="2">
                <v-text-field
                  :disabled="!edit"
                  label="*Altura"
                  v-model="data.alturaMonte"
                  :hint="alturaHint"
                    type="number"
                    onkeydown="return event.keyCode !== 69 && event.keyCode !== 188 && event.keyCode !== 189 && event.keyCode !== 190"
                  :rules="[required('altura'), positive('altura')]"
                ></v-text-field>
                </v-col>
                <v-col cols="3">
                <v-select return-object
                  :disabled="!edit"
                  label="*Especie"
                  v-model="data.tiposDeEspeciesMontesForestalesId"
                  item-text="nombre"
                  :menu-props="{closeOnContentClick: true}"
                    :items="$store.state.tiposDeEspeciesMontesForestales"
                  :rules="[required('especie')]"
                >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="data.tiposDeEspeciesMontesForestalesId = null">
                        <v-list-item-action>
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Sin dato </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="edit" color="primary" :disabled="!valid" @click="commit">Guardar</v-btn>
          <v-btn v-if="edit" @click="cancel">Cancelar</v-btn>
          <v-btn v-if="!edit" @click="close">Cerrar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import validations from "../mixins/validations.js";

export default {
  name: "MonteForestal",
  props: ["data", "dialog", "edit"],
  mixins: [validations],
  mounted() {},
  data() {
    return {
      edadHint: '',
      alturaHint: '',
      dapHint: '',
      tiposDeMontes: [
          {value: {id: 3, nombre: 'Abrigo / Sombra'}, text: 'Abrigo / Sombra'},
          {value: {id: 4, nombre: 'Rendimiento'}, text: 'Rendimiento'},
          {value: {id: 5, nombre: 'Cortinas'}, text: 'Cortinas'}
      ],
      valid: false,
      boolItems: [{text: 'Si', value: true}, {text: 'No', value: false}]
    };
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    commit: function () {
      this.$emit("commit");
    },
    cancel: function () {
      this.$emit("cancel");
    },
  },
  computed: {
    tiposDeMontesForestales(){
      return this.$store.state.tiposDeMontes.filter(el => el.codigo === 'DAB' || el.codigo === 'DRE' || el.codigo === 'COR');
    },
    edad() {
      return (this.data) ? this.data.edad : null;
    },
    altura() {
      return (this.data) ? this.data.alturaMonte : null;
    },
    dap() {
      return (this.data) ? this.data.dap : null;
    },
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    edad(newSel){
      if (newSel > 999){
        this.edadHint = "atencion: edad mayor a 999...";
      } else {
        this.edadHint = "";
      }
    },
    altura(newSel){
      if (newSel > 99){
        this.alturaHint = "atencion: altura mayor a 99...";
      } else {
        this.alturaHint = "";
      }
    },
    dap(newSel){
      if (newSel > 9){
        this.dapHint = "atencion: dap mayor a 9...";
      } else {
        this.dapHint = "";
      }
    },
    show(newSel) {
      if (newSel) {
        this.$nextTick(function () {
          this.$refs.salaDialog.stackMinZIndex = 10000;
          this.$refs.form.validate();
        });
      }
    },
  },
};
</script>

<style scoped>
.over {
  z-index: 10000 !important;
}
</style>
